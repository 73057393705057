<template>
  <div class="d-lg-flex d-block justify-content-start gap-3 mb-3 w-100">
    <MDBSelect name="setYear" id="setYear" v-model:options="options" v-model:selected="setYear" />
    <MDBBtn class="d-flex justify-self-start mt-lg-0 mt-2" type="button" @click="viewRevenue" color="primary"> View
    </MDBBtn>
  </div>
  <MDBTable hover class="align-middle mb-0 bg-white">
    <thead class="table-light">
      <tr>
        <th v-for="(item, i) in tableHeaders" :key="i" scope="col">
          {{ item.header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, i) in revenueList.data" :key="i">
        <td>{{ item.monthName }}</td>
        <td>{{ item.year }}</td>
        <td>${{ addCommaNumbers(item.revenue.toFixed(2)) }}</td>
      </tr>
    </tbody>
  </MDBTable>
</template>

<script setup>
import { MDBTable, MDBSelect, MDBBtn } from "mdb-vue-ui-kit";
import { computed, onBeforeMount, ref } from "vue";
import axios from "axios";
import { addCommaNumbers } from "../helpers/addCommaNumbers";
import { useImpersonateStore } from "../store/impersonateStore";
import { storeToRefs } from "pinia";

const impersonateStore = useImpersonateStore();
const { isImpersonating, userImpersonateDetail } =
  storeToRefs(impersonateStore);

const tableHeaders = computed(() => [
  {
    header: "Month",
  },
  {
    header: "Year",
  },
  {
    header: "Revenue",
  },
]);

const currentYear = new Date().getFullYear();
const options = computed(() => {
  const yearsArray = Array.from(
    { length: currentYear - 2020 },
    (_, index) => currentYear - index
  );
  return yearsArray.map((year) => ({ text: `${year}`, value: year }));
});

const setYear = ref(currentYear);

const revenueList = ref({
  data: {},
  paginaton: [],
});

const viewRevenue = async () => {
  const url = isImpersonating.value
    ? `api/revenues/List?rowPerPage=12&fromYear=${setYear.value}&toYear=${setYear.value}&userId=${userImpersonateDetail.value.id}&isImpersonation=true`
    : `api/revenues/List?rowPerPage=12&fromYear=${setYear.value}&toYear=${setYear.value}`;
  await axios
    .get(url)
    .then(function (response) {
      const data = response.data.data.sort((a, b) => a.month - b.month)
      revenueList.value =
      {
        data: data,
        paginaton: response.data.pagination,
      };
    })
    .catch(function (error) {
      alert("An error occurred during request " + error);
    });
};

onBeforeMount(() => {
  viewRevenue();
});
</script>

<style lang="scss" scoped>
.btn {
  background-color: var(--primary);
}
</style>
