<template>
  <LayoutNew>
    <h1 class="text-center" v-if="!isImpersonating">
      Welcome back,
      <span class="text-capitalize">{{ userDetail.firstName }}! 👋</span>
    </h1>
    <div :class="isMobile ? 'd-block' : 'first-row'">
      <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''">
        <MDBCardHeader> Previous Month Revenue </MDBCardHeader>
        <MDBCardBody>
          <MDBCardTitle>
            <MDBSpinner grow class="" v-if="widgetLoader.loader1" />
            <template v-else>
              ${{ addCommaNumbers(assetWidgets.revenueMonth.toFixed(2)) }}
            </template>
          </MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
      <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''">
        <MDBCardHeader>Current Year Revenue</MDBCardHeader>
        <MDBCardBody>
          <MDBCardTitle>
            <MDBSpinner grow v-if="widgetLoader.loader2" />
            <template v-else>
              ${{ addCommaNumbers(assetWidgets.revenueYear.toFixed(2)) }}
            </template>
          </MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
      <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''">
        <MDBCardHeader>Total Revenue</MDBCardHeader>
        <MDBCardBody>
          <MDBCardTitle>
            <MDBSpinner grow v-if="widgetLoader.loader2" />
            <template v-else>
              ${{ addCommaNumbers(assetWidgets.revenueTotal.toFixed(2)) }}
            </template>
          </MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
      <MDBCard>
        <MDBCardHeader>Assigned Labels</MDBCardHeader>
        <MDBCardBody>
          <MDBCardTitle>
            <MDBSpinner grow v-if="widgetLoader.loader2" />
            <template v-else>
              {{ addCommaNumbers(assetWidgets.AssignedLabelsCount) }}
            </template>
          </MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
    </div>
    <div :class="isMobile ? 'd-block' : 'second-row'" v-if="secondRow">
      <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''">
        <MDBCardHeader>Gross Month Revenue</MDBCardHeader>
        <MDBCardBody>
          <MDBCardTitle>
            <MDBSpinner grow v-if="widgetLoader.loader2" />
            <template v-else>
              ${{ addCommaNumbers(assetWidgets.grossMonth.toFixed(2)) }}
            </template>
          </MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
      <MDBCard :class="isMobile ? 'mb-3 mb-lg-0' : ''">
        <MDBCardHeader>Gross Year Revenue</MDBCardHeader>
        <MDBCardBody>
          <MDBCardTitle>
            <MDBSpinner grow v-if="widgetLoader.loader2" />
            <template v-else>
              ${{ addCommaNumbers(assetWidgets.grossYear.toFixed(2)) }}
            </template>
          </MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
      <MDBCard>
        <MDBCardHeader>Gross Total Revenue</MDBCardHeader>
        <MDBCardBody>
          <MDBCardTitle>
            <MDBSpinner grow v-if="widgetLoader.loader2" />
            <template v-else>
              ${{ addCommaNumbers(assetWidgets.grossTotal.toFixed(2)) }}
            </template>
          </MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
    </div>
    <MDBCard>
      <MDBCardHeader> Revenue Chart </MDBCardHeader>
      <MDBCardBody>
        <MDBChart type="bar" indexAxis="y" :data="revenueChartData" id="revenue-chart" />
      </MDBCardBody>
    </MDBCard>
    <MDBCard>
      <MDBCardHeader> Revenue Data </MDBCardHeader>
      <MDBCardBody class="overflow-auto">
        <RevenuesTableComponent />
      </MDBCardBody>
    </MDBCard>
  </LayoutNew>
</template>

<script setup>
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBChart,
  MDBSpinner,
} from "mdb-vue-ui-kit";
import { onMounted, ref, onUnmounted, computed, onBeforeUnmount } from "vue";
import { useUserDetailStore } from "../../store/userDetailStore";
import { GetCurrent } from "../../services/Users/GetCurrent";
import { useTitle } from "@vueuse/core";
import { storeToRefs } from "pinia";
import axios from "axios";
import { GetRevenueWidgets } from "../../services/Revenue/GetRevenueWidget";
import { addCommaNumbers } from "../../helpers/addCommaNumbers";
import RevenuesTableComponent from "../../components/RevenuesTableComponent.vue";
import LayoutNew from "./LayoutNew.vue";
import { useImpersonateStore } from "../../store/impersonateStore";

useTitle("Dashboard | CreatorShield");

const userDetailStore = useUserDetailStore();
const { userDetail } = storeToRefs(userDetailStore);
const revenueChartData = ref({});

const assetWidgets = ref({
  revenueMonth: 0,
  revenueYear: 0,
  revenueTotal: 0,
  AssignedLabelsCount: 0,
  grossMonth: 0,
  grossYear: 0,
  grossTotal: 0,
});

const widgetLoader = ref({
  loader1: true,
  loader2: true,
  loader3: true,
  loader4: true,
  loader5: true,
  loader6: true,
  loader7: true,
});

const impersonateStore = useImpersonateStore();
const { isImpersonating, userImpersonateDetail } =
  storeToRefs(impersonateStore);
onUnmounted(() => {
  isImpersonating.value = false;
});

onMounted(async () => {
  const response = await GetCurrent();
  userDetail.value = response;
  const impersonateId = isImpersonating.value
    ? userImpersonateDetail.value.id
    : null;
  const chartUrl = impersonateId
    ? `api/revenues/ChartData?userId=${impersonateId}&isImpersonation=true`
    : "api/revenues/ChartData";
  await axios
    .get(chartUrl)
    .then(function (response) {
      const dataset = response.data.data;
      const labels = response.data.labels;
      revenueChartData.value = {
        labels,
        datasets: [
          {
            data: dataset,
            label: "Revenue ($)",
            backgroundColor: new Array(dataset.length).fill(
              "rgba(2, 6, 27, 0.7)"
            ),
            borderColor: new Array(dataset.length).fill("#fc5818"),
            borderWidth: 0.5,
          },
        ],
      };
    })
    .catch(function (error) {
      alert("An error occurred during request " + error);
    });
  GetRevenueWidgets("RevenueMonth", impersonateId).then((response) => {
    assetWidgets.value.revenueMonth = response;
    widgetLoader.value.loader1 = false;
  });
  GetRevenueWidgets("RevenueYear", impersonateId).then((response) => {
    assetWidgets.value.revenueYear = response;
    widgetLoader.value.loader2 = false;
  });
  GetRevenueWidgets("RevenueTotal", impersonateId).then((response) => {
    assetWidgets.value.revenueTotal = response;
    widgetLoader.value.loader3 = false;
  });
  GetRevenueWidgets("AssignedLabelsCount", impersonateId).then((response) => {
    assetWidgets.value.AssignedLabelsCount = response;
    widgetLoader.value.loader4 = false;
  });
  GetRevenueWidgets("GrossRevenueMonth", impersonateId).then((response) => {
    assetWidgets.value.grossMonth = response;
    widgetLoader.value.loader5 = false;
  });
  GetRevenueWidgets("GrossRevenueYear", impersonateId).then((response) => {
    assetWidgets.value.grossYear = response;
    widgetLoader.value.loader6 = false;
  });
  GetRevenueWidgets("GrossRevenueTotal", impersonateId).then((response) => {
    assetWidgets.value.grossTotal = response;
    widgetLoader.value.loader7 = false;
  });
});

const secondRow = computed(() => {
  if (isImpersonating.value) {
    return userImpersonateDetail.value.userTypeId == 1;
  }
  return userDetail.value.userTypeId == 1;
});

const isMobile = ref(window.innerWidth <= 767);

const updateResolution = () => {
  isMobile.value = window.innerWidth <= 767;
};

onMounted(() => {
  window.addEventListener('resize', updateResolution);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateResolution);
});
</script>

<style scoped>
.first-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.second-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.spinner-grow {
  color: var(--accent);
}

.card {
  border-radius: 16px;
}

.card-header {
  background: var(--accent);
  color: white;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  text-align: center;
}

.card-title {
  color: var(--primary);
}

.card-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
</style>
