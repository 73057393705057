import { HttpClient } from "../HttpClient";
/**
 * * Get Revenue Widgets
 */
export const GetRevenueWidgets = async (url, userId) => {
  try {
    const endpointUrl = userId
      ? `api/revenues/${url}?userId=${userId}&isImpersonation=true`
      : `api/revenues/${url}`;
    const { data } = await HttpClient.get(endpointUrl);
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
